var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: { block: "", color: "info", disabled: _vm.orders.length === 0 },
      on: { click: _vm.open },
    },
    [
      _vm._v(" Print Picking Slips "),
      _vm.orders.length > 0
        ? _c("span", [_vm._v(" (" + _vm._s(_vm.orders.length) + ") ")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }