var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.mobile
            ? _c("v-col", { staticClass: "w-100", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-stretch background--grey w-100 pa-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-100 mr-2 pa-2",
                        class: {
                          "background--white": _vm.selected === "Invoices",
                        },
                        on: {
                          click: function ($event) {
                            _vm.selected = "Invoices"
                          },
                        },
                      },
                      [_vm._v(" Invoices ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-100 mr-2 pa-2",
                        class: {
                          "background--white": _vm.selected === "Credit Notes",
                        },
                        on: {
                          click: function ($event) {
                            _vm.selected = "Credit Notes"
                          },
                        },
                      },
                      [_vm._v(" Credit Notes ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-stretch background--grey w-100 pa-2",
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "black--text",
                        attrs: {
                          color: "accent",
                          block: "",
                          loading: _vm.isEmailing,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.emailStatement()
                          },
                        },
                      },
                      [_vm._v(" Email Statement ")]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: {
                        color: _vm.selected === "Invoices" ? "primary" : "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.selected = "Invoices"
                        },
                      },
                    },
                    [_vm._v(" Invoices ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: {
                        color: _vm.selected === "Credit Notes" ? "primary" : "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.selected = "Credit Notes"
                        },
                      },
                    },
                    [_vm._v(" Credit Notes ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "black--text",
                      attrs: { color: "accent", loading: _vm.isDownloading },
                      on: {
                        click: function ($event) {
                          return _vm.downloadStatement()
                        },
                      },
                    },
                    [_vm._v(" Download Statement ")]
                  ),
                ],
                1
              ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.selected === "Invoices"
                ? _c("ListFinanceDocuments", {
                    attrs: { arType: "OInv", valueProp: "debit" },
                  })
                : _vm._e(),
              _vm.selected === "Credit Notes"
                ? _c("ListFinanceDocuments", {
                    attrs: { arType: "Crn", valueProp: "credit" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }