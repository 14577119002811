<template>
  <v-btn block color="info" @click="open"  :disabled="orders.length === 0">
    Print Picking Slips
    <span v-if="orders.length > 0">
      ({{ orders.length }})
    </span>
  </v-btn>
</template>

<script>
import PickingSlipTemplate from './PickingSlipTemplate.txt';
import PickingSlipRowTemplate from './PickingSlipRowTemplate.txt';
import PickSlipHtml from './PickSlip.txt';

export default {
  name: 'PrintText',
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      PickingSlipTemplate,
      PickingSlipRowTemplate,
      PickSlipHtml,
    };
  },
  computed: {
  },
  methods: {
    async open() {
      this.orders.forEach(async (order) => {
        const salesOrder = await this.loadSalesOrder(order.externalOrderNo);
        await this.updatePrintCount(salesOrder);
        const salesOrderLines = await this.loadSalesOrderLines(salesOrder.id);
        const linesByPrinter = this.linesByPrinterArray(salesOrderLines);
        this.printText(linesByPrinter, salesOrder);
      });
    },
    async loadSalesOrder(externalOrderNo) {
      const { data } = await this.$http.get(`/odata/salesOrders(${externalOrderNo})?$expand=lines($filter=IsDeleted eq false)`);
      console.log(data);
      return data;
    },
    async loadSalesOrderLines(salesOrderId) {
      const { data } = await this.$http.get(`/odata/salesOrderLines?$filter=SalesOrderId eq ${salesOrderId} and IsDeleted eq false`);
      return data.value;
    },
    async updatePrintCount(salesOrder) {
      await this.$http.patch(`/odata/salesOrders(${salesOrder.id})`, {
        printCount: salesOrder.printCount + 1,
      });
    },
    linesByPrinterArray(salesOrderLines) {
      const groupedLines = salesOrderLines.reduce((acc, line) => {
        if (!acc[line.printer]) {
          acc[line.printer] = [];
        }
        acc[line.printer].push(line);
        return acc;
      }, {});

      return Object.keys(groupedLines).map((printer) => ({
        name: printer,
        lines: groupedLines[printer],
      }));
    },
    printText(linesArray, salesOrder) {
      linesArray.forEach((printer) => {
        this.printTextToPrinter(printer.name, printer.lines, salesOrder);
      });
    },
    printTextToPrinter(printer, lines, salesOrder) {
      const text = this.generateText(printer, lines, salesOrder);
      // Create a hidden iframe to load the text content for printing
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = '0';
      document.body.appendChild(iframe);

      // Write the text content into the iframe with Times New Roman font
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
      <style>
        pre {
        font-family: 'Times New Roman', Times, serif;
        }
      </style>
      <pre>${text}</pre>
      `);
      doc.close();

      // Print the content of the iframe
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    },
    generateText(printer, lines, salesOrder) {
      return this.PickSlipHtml
        .replace('[CompanyName]', salesOrder.customerName.padEnd(56, ' '))
        .replace('[DeliveryAddress1]', salesOrder.deliveryAddress1.padEnd(56, ' '))
        .replace('[DeliveryAddress2]', salesOrder.deliveryAddress2.padEnd(56, ' '))
        .replace('[DeliveryAddress3]', salesOrder.deliveryAddress3.padEnd(56, ' '))
        .replace('[DeliveryAddress4]', salesOrder.deliveryAddress4.padEnd(56, ' '))
        .replace('[DeliveryDate]', this.formatDate(salesOrder.deliveryDate))
        .replace('[CreatedBy]', salesOrder.createdBy.padEnd(50, ' '))
        .replace('[SalesOrderId]', salesOrder.id?.toString())
        .replace('[PrintedDate]', this.formatDateTime(new Date()))
        .replace('[Version]', salesOrder.printCount + 1)
        .replace('[Printer]', printer)
        .replace('[Rows]', lines.map((line) => this.PickingSlipRowTemplate
          .replace('[ItemCode]', line.itemCode.padEnd(14, ' '))
          .replace('[Description]', line.itemDescription.padEnd(36, ' '))
          .replace('[Quantity]', line.quantity.toString().padEnd(5, ' '))
          .replace('[UnitOfMeasure]', line.unitOfMeasure.padEnd(7, ' '))
          .replace('[Bin]', line.bin?.padEnd(10, ' '))).join('\n'));
    },
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap; /* Ensure the text is wrapped properly */
  word-wrap: break-word; /* Ensure long words are wrapped properly */
}
</style>
