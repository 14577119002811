<template>
  <v-btn color="success" block @click="completeSalesOrders" :disabled="salesOrders.length === 0">
    Complete <span v-if="salesOrders.length > 0">
      ({{ salesOrders.length }})
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'CompleteSalesOrders',
  props: {
    salesOrders: {
      type: Array,
      required: true,
    },
  },
  methods: {
    completeSalesOrders() {
      console.log('Complete sales orders', this.salesOrders);
    },
  },
};
</script>
