var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Breadcrumbs", { attrs: { history: _vm.history } }),
      _vm.loadingHeader ? _c("ProgressLinearTimeout") : _vm._e(),
      !_vm.loadingHeader
        ? _c("div", { staticClass: "text-h5" }, [_vm._v("Deliveries:")])
        : _vm._e(),
      !_vm.loadingHeader
        ? _c("AdminOrderSummary", {
            attrs: { orders: _vm.orderHeaders },
            on: { click: _vm.onDateFilter },
          })
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  "elevation-1": "",
                  headers: _vm.headers,
                  items: _vm.visibleOrders,
                  expanded: _vm.expanded,
                  loading: _vm.isLoading,
                  "show-expand": "",
                  "show-select": _vm.selectedFilter === "Ready",
                  "item-key": "id",
                  search: _vm.search,
                  "sort-by": "created",
                  "sort-desc": true,
                  "no-data-text": "All orders have been processed",
                },
                on: {
                  refresh: _vm.refresh,
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex justify-center align-content-space-between",
                                attrs: { cols: "12", lg: "3" },
                              },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    label: "From",
                                    filled: false,
                                    disabled: _vm.deliveryDate !== undefined,
                                  },
                                  on: { input: _vm.refresh },
                                  model: {
                                    value: _vm.start,
                                    callback: function ($$v) {
                                      _vm.start = $$v
                                    },
                                    expression: "start",
                                  },
                                }),
                                _c("div", { staticClass: "mr-2" }),
                                _c("DatePicker", {
                                  attrs: {
                                    label: "To",
                                    filled: false,
                                    disabled: _vm.deliveryDate !== undefined,
                                  },
                                  on: { input: _vm.refresh },
                                  model: {
                                    value: _vm.end,
                                    callback: function ($$v) {
                                      _vm.end = $$v
                                    },
                                    expression: "end",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "2" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    label: "Delivery Date",
                                    filled: false,
                                    clearable: "",
                                  },
                                  on: { input: _vm.refresh },
                                  model: {
                                    value: _vm.deliveryDate,
                                    callback: function ($$v) {
                                      _vm.deliveryDate = $$v
                                    },
                                    expression: "deliveryDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "2" } },
                              [
                                _c("v-select", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    clearable: "",
                                    "data-cy": "TableBasic-filter",
                                    items: _vm.filterItems,
                                    label: "Filter",
                                    "single-line": "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.selectedFilter,
                                    callback: function ($$v) {
                                      _vm.selectedFilter = $$v
                                    },
                                    expression: "selectedFilter",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "2" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    clearable: "",
                                    "data-cy": "TableBasic-search",
                                    "append-icon": _vm.mdiMagnify,
                                    label: _vm.$t("common.search"),
                                    "single-line": "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex justify-start align-center",
                                attrs: { cols: "12", lg: "3" },
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: { id: "input-show-completed" },
                                  model: {
                                    value: _vm.showCompleted,
                                    callback: function ($$v) {
                                      _vm.showCompleted = $$v
                                    },
                                    expression: "showCompleted",
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-caption mr-2",
                                    attrs: { for: "input-show-completed" },
                                  },
                                  [_vm._v(" Show Completed ")]
                                ),
                                _c("v-checkbox", {
                                  attrs: { id: "input-auto-refresh" },
                                  model: {
                                    value: _vm.isAutoRefresh,
                                    callback: function ($$v) {
                                      _vm.isAutoRefresh = $$v
                                    },
                                    expression: "isAutoRefresh",
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-caption",
                                    attrs: { for: "input-auto-refresh" },
                                  },
                                  [_vm._v("Auth Refresh")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", color: "primary" },
                                    on: { click: _vm.refresh },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.mdiRefresh)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.selectedOrders.length > 0
                          ? _c(
                              "v-row",
                              { staticClass: "mt-0" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c("PrintPickingSlips", {
                                      attrs: { orders: _vm.selectedOrders },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.created",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDateTime(item.created)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.customerId",
                    fn: function ({ item }) {
                      return [
                        _c("CustomerName", {
                          attrs: {
                            customers: _vm.customers,
                            id: item.customerId,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.area",
                    fn: function ({ item }) {
                      return [
                        _c("CustomerArea", {
                          attrs: {
                            customers: _vm.customers,
                            arCustomers: _vm.arCustomers,
                            id: item.customerId,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "expanded-item",
                    fn: function ({ headers, item }) {
                      return [
                        _c(
                          "td",
                          {
                            staticStyle: { padding: "10px" },
                            attrs: { colspan: headers.length },
                          },
                          [
                            _c("v-data-table", {
                              attrs: {
                                headers: _vm.lineHeaders,
                                items: item.cart,
                                loading: item.isLoading,
                                "elevation-0": "",
                                "items-per-page": -1,
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.price",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatNumber(item.price)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.vat",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formatNumber(item.vat)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.total",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatNumber(item.total)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.externalOrderNo",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "shop-ViewSalesOrder",
                                query: {
                                  orderId: item.id,
                                  salesOrderId: item.externalOrderNo,
                                },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.externalOrderNo) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.updated",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDateTime(item.updated)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.deliveryDate",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              color: "primary",
                              small: "",
                              icon: "",
                              disabled: item.status === "Integrated",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editDeliveryDate(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(_vm._s(_vm.mdiPencil)),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" " + _vm._s(item.deliveryDate) + " "),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c("PrintText", { attrs: { order: item } }),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column pa-2" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  small: "",
                                  color: "primary",
                                  loading: item.isSaving,
                                  block: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.force(item)
                                  },
                                },
                              },
                              [_vm._v(" Force ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  small: "",
                                  color: "success",
                                  disabled: item.status !== "Failed",
                                  loading: item.isSaving,
                                  block: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.requeue(item)
                                  },
                                },
                              },
                              [_vm._v(" Requeue ")]
                            ),
                            _c("XmlAuditDialog", {
                              attrs: { orderId: item.id },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedOrders,
                  callback: function ($$v) {
                    _vm.selectedOrders = $$v
                  },
                  expression: "selectedOrders",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.dialogDeliveryDate,
            callback: function ($$v) {
              _vm.dialogDeliveryDate = $$v
            },
            expression: "dialogDeliveryDate",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "text-h5 grey lighten-2 d-flex flex-column align-start",
                },
                [
                  _c("span", [
                    _vm._v(
                      " Editing Order " + _vm._s(_vm.editItem.orderNo) + " "
                    ),
                  ]),
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v(
                      " Created by " + _vm._s(_vm.editItem.createdBy) + " "
                    ),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c("DatePicker", {
                    attrs: {
                      label: "Delivery Date",
                      filled: false,
                      clearable: "",
                    },
                    model: {
                      value: _vm.editItem.deliveryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editItem, "deliveryDate", $$v)
                      },
                      expression: "editItem.deliveryDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogDeliveryDate = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", loading: _vm.isSavingItem },
                      on: {
                        click: function ($event) {
                          return _vm.updateDeliveryDate(_vm.editItem)
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }