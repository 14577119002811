var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.salesOrder
        ? _c("SalesOrderHeader", { attrs: { "sales-order": _vm.salesOrder } })
        : _vm._e(),
      _vm.salesOrder
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _vm.canEdit
                    ? _c("DialogAddSalesOrderLine", {
                        attrs: { "sales-order": _vm.salesOrder },
                        on: { add: _vm.addStockOrderLine },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "4" } },
                [
                  _vm._v("Order Number: "),
                  _c("b", [_vm._v(_vm._s(_vm.salesOrder.id))]),
                ]
              ),
              _c("v-col", { staticClass: "text-right", attrs: { cols: "4" } }, [
                _vm._v("User: " + _vm._s(_vm.username)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
              _c("table", { staticClass: "table-picking" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(" Code ")]),
                    _c("th", [_vm._v(" Description ")]),
                    _c("th", [_vm._v(" Qty ")]),
                    _c("th", [_vm._v(" Unit ")]),
                    _c("th", [_vm._v(" Bin ")]),
                    _c("th", [_vm._v(" Printer ")]),
                    _c("th", [_vm._v(" Picked ")]),
                    _vm.canEdit ? _c("th") : _vm._e(),
                  ]),
                ]),
                _vm.lines.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.lines, function (line, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(line.itemCode))]),
                          _c("td", [_vm._v(_vm._s(line.itemDescription))]),
                          _c("td", [
                            _vm.canEdit
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: line.originalQuantity,
                                      expression: "line.originalQuantity",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  attrs: { type: "number" },
                                  domProps: { value: line.originalQuantity },
                                  on: {
                                    focus: _vm.onFocus,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        line,
                                        "originalQuantity",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(line.originalQuantity) + " "
                                  ),
                                ]),
                          ]),
                          _c("td", [_vm._v(_vm._s(line.unitOfMeasure))]),
                          _c("td", [_vm._v(_vm._s(line.bin))]),
                          _c("td", [_vm._v(_vm._s(line.printer))]),
                          _c("td", [_vm._v(_vm._s(line.quantity))]),
                          _vm.canEdit
                            ? _c(
                                "td",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeLine(line)
                                        },
                                      },
                                    },
                                    [_vm._v(" Delete ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.canEdit
        ? _c(
            "v-row",
            [
              _vm.salesOrder
                ? _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("PrintPickingSlip", {
                        attrs: {
                          "sales-order": _vm.salesOrder,
                          lines: _vm.lines,
                        },
                        on: { updatePrintCount: _vm.updatePrintCount },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "flex flex-column align-end",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        block: "",
                        color: "accent",
                        loading: _vm.isSaving,
                      },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "flex flex-column align-end",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        block: "",
                        color: "success",
                        to: {
                          name: "shop-PickSalesOrder",
                          query: {
                            orderId: _vm.order.id,
                            salesOrderId: _vm.salesOrder.id,
                          },
                        },
                      },
                    },
                    [_vm._v(" Pick ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }