<template>
  <v-card>
    <slot name="top"></slot>
    <v-card-title primary-title>
      {{ title }}
      <v-btn icon @click="refresh">
        <v-icon>{{ mdiRefresh }}</v-icon>
      </v-btn>
      <slot name="title-buttons"></slot>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="eventLogs"
        :loading="isLoading"
        :options.sync="pagination"
        item-key="traceId"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100, 250, 500],
        }"
        flat
        :server-items-length="pagination.totalItems"
      >
        <template v-slot:item.utcDateTime="{ item }">
          {{ formatUtcTime(item.utcDateTime) }}
        </template>
        <template v-slot:top>
          <!-- <v-toolbar
            flat
          >
            <v-row>
              <v-col cols="6" offset="6">
                <v-text-field
                  :append-icon="mdiMagnify"
                  label="Search"
                  clearable
                  @input="searchChanged"
                  @click:clear="search = ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar> -->
        </template>
        <template v-slot:footer>
          <slot name="footer"></slot>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMagnify, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';
import ODataQueryBuilder from '../ODataQueryBuilder';

export default {
  name: 'SourceEventLogs',
  mixins: [loading],
  props: {
    title: {
      type: String,
      default: 'Actvity',
    },
    filter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      eventLogs: [],
      // sources: [],
      // selectedSource: undefined,
      queryBuilder: undefined,
      headers: [
        { text: 'Date and Time', value: 'utcDateTime', searchable: false },
        { text: 'Source', value: 'source', searchable: true },
        // { value: 'logicalOperationStack', text: 'Logical Operation Stack', searchable: false },
        // { value: 'relatedActivityId', text: 'Related Activity Id', searchable: false },
        { text: 'Type', value: 'eventType', searchable: true },
        { text: 'Message', value: 'message', searchable: true },
        { text: 'Data', value: 'data', searchable: true },
      ],
      search: '',
      pagination: {
        page: 0,
        itemsPerPage: 10,
        sortBy: ['utcDateTime'],
        sortDesc: [true],
        totalItems: -1,
      },
    };
  },
  watch: {
    pagination() {
      this.refresh();
    },
    search() {
      this.refresh();
    },
  },
  async mounted() {
    const { headers } = this;
    this.queryBuilder = new ODataQueryBuilder('odata', 'diagnosticsTraces', headers);
    await this.refresh();
  },
  methods: {
    formatUtcTime(utcDateTime) {
      return dayjs(utcDateTime)
        .add(2, 'hour')
        .format('YYYY-MM-DD HH:mm:ss');
    },
    async refresh() {
      this.isLoading = true;
      const { filter, queryBuilder, search } = this;
      await Promise.all([
        (async () => {
          const {
            page, itemsPerPage, sortBy, sortDesc,
          } = this.pagination;

          const url = queryBuilder.buildUrl({
            top: itemsPerPage,
            filter,
            page,
            search,
            sortBy,
            sortDesc,
          });
          const { data } = await this.$http.get(url);
          this.pagination.totalItems = data['@odata.count'];
          this.eventLogs = data.value;
        })(),
        // (async () => {
        // eslint-disable-next-line max-len
        //   const { data } = await this.$http.get('odata/diagnosticsTraces?$apply=groupby((source))');
        //   this.sources = data.value.map((s) => s.source);
        // })(),
      ]);
      this.isLoading = false;
    },
    searchChanged(val) {
      this.search = val;
    },
  },
};
</script>
