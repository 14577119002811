<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" small v-bind="attrs"
      v-on="on" block>View Logs</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">XML Audit</span>
      </v-card-title>
      <v-card-text>
        <ProgressLinearTimeout v-if="isLoading" />
        <v-tabs v-model="activeTab">
          <v-tab>Activity</v-tab>
          <v-tab>Request</v-tab>
          <v-tab>Response</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <SourceEventLogs :filter="filter" title="" />
          </v-tab-item>
          <v-tab-item>
            <textarea class="xml-textarea" v-html="xmlContent1" />
          </v-tab-item>
          <v-tab-item>
            <textarea class="xml-textarea" v-html="xmlContent2" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import SourceEventLogs from '@/components/SourceEventLogs.vue';

export default {
  name: 'XmlAuditDialog',
  mixins: [loading],
  components: {
    ProgressLinearTimeout,
    SourceEventLogs,
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      activeTab: 0,
      xmlContent1: '',
      xmlContent2: '',
      activities: '',
    };
  },
  computed: {
    filter() {
      return `ActivityId eq ${this.orderId}`;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      await this.loadXmlAudits();
      this.isLoading = false;
    },
    async loadXmlAudits() {
      const oDataUrl = `odata/xmlAudits?$filter=orderId eq ${this.orderId}&$orderby=created desc&$top=1`;
      const { data } = await this.$http.get(oDataUrl);
      if (data.value.length) {
        this.xmlContent1 = data.value[0].requestXml;
        this.xmlContent2 = data.value[0].responseXml;
      }
    },
  },
};
</script>
<style scoped>
.xml-textarea {
  width: 100%;
  min-height: 300px;
}
</style>
