var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        color: "success",
        block: "",
        disabled: _vm.salesOrders.length === 0,
      },
      on: { click: _vm.completeSalesOrders },
    },
    [
      _vm._v(" Complete "),
      _vm.salesOrders.length > 0
        ? _c("span", [_vm._v(" (" + _vm._s(_vm.salesOrders.length) + ") ")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }