var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mb-2", attrs: { dense: "" } },
    _vm._l(_vm.deliveryDays, function (delivery) {
      return _c(
        "v-col",
        { key: delivery.day, attrs: { cols: "6", md: "4", lg: "2" } },
        [
          _c("StatisticsCard", {
            attrs: {
              statTitle: delivery.day,
              subtitle: delivery.deliveries.toString(),
              "foot-note": delivery.date,
              total: delivery.cartTotal.toFixed(0),
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click", delivery.date)
              },
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }