var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", small: "", block: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v("View Logs")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v("XML Audit")]),
          ]),
          _c(
            "v-card-text",
            [
              _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("v-tab", [_vm._v("Activity")]),
                  _c("v-tab", [_vm._v("Request")]),
                  _c("v-tab", [_vm._v("Response")]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c("SourceEventLogs", {
                        attrs: { filter: _vm.filter, title: "" },
                      }),
                    ],
                    1
                  ),
                  _c("v-tab-item", [
                    _c("textarea", {
                      staticClass: "xml-textarea",
                      domProps: { innerHTML: _vm._s(_vm.xmlContent1) },
                    }),
                  ]),
                  _c("v-tab-item", [
                    _c("textarea", {
                      staticClass: "xml-textarea",
                      domProps: { innerHTML: _vm._s(_vm.xmlContent2) },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }