var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    { attrs: { block: "", color: "info" }, on: { click: _vm.open } },
    [_vm._v(" Print Picking Slip (" + _vm._s(_vm.salesOrder.printCount) + ") ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }