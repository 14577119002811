var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _vm._t("top"),
      _c(
        "v-card-title",
        { attrs: { "primary-title": "" } },
        [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.refresh } },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
            1
          ),
          _vm._t("title-buttons"),
        ],
        2
      ),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.eventLogs,
              loading: _vm.isLoading,
              options: _vm.pagination,
              "item-key": "traceId",
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100, 250, 500],
              },
              flat: "",
              "server-items-length": _vm.pagination.totalItems,
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.utcDateTime",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.formatUtcTime(item.utcDateTime)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "top",
                  fn: function () {
                    return undefined
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [_vm._t("footer")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }