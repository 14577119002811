var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.loadingHeader ? _c("ProgressLinearTimeout") : _vm._e(),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                _vm._l(_vm.orders, function (item) {
                  return _c(
                    "v-col",
                    { key: item.id, attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v(
                              _vm._s(item.externalOrderNo) +
                                ": " +
                                _vm._s(item.orderNo)
                            ),
                          ]),
                          _c("v-card-subtitle", [
                            _vm._v("Delivery Date: "),
                            _c("b", [_vm._v(_vm._s(item.deliveryDate))]),
                          ]),
                          _c("v-card-text", [
                            _vm._v("Created By: "),
                            _c("b", [_vm._v(_vm._s(item.createdBy))]),
                          ]),
                          _c("v-card-text", [
                            _c(
                              "table",
                              { staticClass: "table-bordered w-100" },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("Quantity")]),
                                    _c("th", [_vm._v("Stock")]),
                                    _c("th", [_vm._v("Total")]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(item.lines, function (line) {
                                    return _c("tr", { key: line.id }, [
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(line.amount)),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              line.stockItem?.description ??
                                                line.itemCode
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          _vm._s(_vm.formatCurrency(line.total))
                                        ),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    small: "",
                                    color: "success",
                                    loading: item.isSaving,
                                    block: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reorder(item)
                                    },
                                  },
                                },
                                [_vm._v(" Reorder ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    attrs: {
                      "elevation-1": "",
                      headers: _vm.headers,
                      items: _vm.orders,
                      expanded: _vm.expanded,
                      loading: _vm.isLoading,
                      "show-expand": "",
                      "item-key": "id",
                      search: _vm.search,
                      "sort-by": "created",
                      "sort-desc": true,
                      "no-data-text": "You have not placed any orders",
                    },
                    on: {
                      refresh: _vm.refresh,
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      lg: "2",
                                      "offset-lg": "4",
                                    },
                                  },
                                  [
                                    _c("DatePicker", {
                                      attrs: { label: "From", filled: false },
                                      model: {
                                        value: _vm.start,
                                        callback: function ($$v) {
                                          _vm.start = $$v
                                        },
                                        expression: "start",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "2" } },
                                  [
                                    _c("DatePicker", {
                                      attrs: { label: "To", filled: false },
                                      model: {
                                        value: _vm.end,
                                        callback: function ($$v) {
                                          _vm.end = $$v
                                        },
                                        expression: "end",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        clearable: "",
                                        "data-cy": "TableBasic-search",
                                        "append-icon": _vm.mdiMagnify,
                                        label: _vm.$t("common.search"),
                                        "single-line": "",
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: _vm.search,
                                        callback: function ($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.created",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDateTime(item.created)) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "expanded-item",
                        fn: function ({ headers, item }) {
                          return [
                            _c(
                              "td",
                              {
                                staticStyle: { padding: "10px" },
                                attrs: { colspan: headers.length },
                              },
                              [
                                _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.lineHeaders,
                                    items: item.lines,
                                    loading: item.isLoading,
                                    "elevation-0": "",
                                    "items-per-page": -1,
                                    "hide-default-footer": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.price",
                                        fn: function ({ item }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatCurrency(item.price)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.tax",
                                        fn: function ({ item }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatCurrency(item.tax)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.total",
                                        fn: function ({ item }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatCurrency(item.total)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.updated",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDateTime(item.updated)) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column pa-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      small: "",
                                      color: "success",
                                      loading: item.isSaving,
                                      block: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.reorder(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" Reorder ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }