<template>
  <table class="table-cart mb-4">
    <thead>
      <tr>
        <th></th>
        <th>Product</th>
        <th>Stock</th>
        <th>Qty</th>
        <th>Price</th>
        <th>VAT</th>
        <th>Total</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in cartItems">
        <tr :key="index" :data-cy="`table-cart-row-${index}`">
          <td>
            <img
              v-if="item.photo"
              width="64"
              height="64"
              contain
              :src="resizeImage(item.stockItem)"
            />
            <img
              v-else
              width="64"
              height="64"
              contain
              src="../assets/default-product-image.png" />
          </td>
          <td>
            <div class="text-normal">{{ item.stockItem.description }}</div>
          </td>
          <td>
            {{ item.stockItem.quantity }}
          </td>
          <td>
            <span v-if="disabled">{{ formatNumber(item.amount) }}</span>
            <QuantityButtonCart
              v-else
              data-cy="cart-quantity-button"
              v-model="item.amount"
              :limit="item.detail.limit?.limit"
              @input="CHANGE_AMOUNT({ itemCode: item.stockItem.itemCode, amount: $event })"
              @remove="REMOVE_CART(item.stockItem.itemCode)"
            />
          </td>
          <td>
            <div class="text-normal">R{{ formatCurrency(item.price) }}</div>
          </td>
          <td>
            <div class="text-normal">R{{ formatCurrency(item.tax) }}</div>
          </td>
          <td>
            <div class="text-normal">R{{ formatCurrency(item.total) }}</div>
          </td>
          <td>
            <v-btn
              small
              color="error"
              :disabled="disabled"
              data-cy="remove-cart-item"
              @click="REMOVE_CART(item.stockItem.itemCode)"
            >
              <v-icon small>{{ mdiTrashCan }}</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <tr>
        <td colspan="3">
          <v-btn v-if="!disabled" color="error" @click="clearCart">Clear entire cart</v-btn>
        </td>
        <td colspan="5" class="small-td text-right">
          <h2>
            <span>Total:</span>
            R {{ formatCurrency(total_amount) }}
          </h2>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import QuantityButtonCart from './QuantityButtonCart.vue';
import cart from '../mixins/cart';
import validation from '../mixins/validation';

export default {
  name: 'TableCart',
  components: {
    QuantityButtonCart,
  },
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [cart, validation],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiTrashCan,
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapState('finance', ['client']),
    headers() {
      let result = [
        {
          text: '',
          value: 'stockItem.photo',
          width: '80px',
        },
        {
          text: 'Product',
          value: 'stockItem.description',
        },
        {
          text: 'Stock',
          value: 'stockItem.quantity',
        },
        {
          text: 'Qty',
          value: 'amount',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'VAT',
          value: 'tax',
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
      if (this.$vuetify.breakpoint.lgAndDown) {
        result = result
          .filter((x) => x.value !== 'stockItem.photo'
            && x.value !== 'stockItem.quantity'
            && x.value !== 'actions');
      }
      if (this.disabled) {
        result = result.filter((x) => x.value !== 'actions');
      }
      return result;
    },
    /**
     * Returns true if the user is in the buyers role
     */
    isApproval() {
      return this.isInRole('Buyers');
    },
  },
  methods: {
    async clearCart() {
      const result = await this.$confirm('Are you sure you want to clear all the items in your cart?');
      if (!result) return;
      this.RESET_CART();
    },
    resizeImage(stockItem) {
      const result = `${stockItem?.photo}` ?? '';
      return result;
    },
  },
};
</script>
