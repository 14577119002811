<template>
  <v-container fluid>
    <SalesOrderHeader v-if="salesOrder" :sales-order="salesOrder" />
    <v-row v-if="salesOrder">
      <v-col cols="4">
        <DialogAddSalesOrderLine
          v-if="canEdit"
          :sales-order="salesOrder"
          @add="addStockOrderLine"
        />
      </v-col>
      <v-col cols="4" class="text-center">Order Number: <b>{{ salesOrder.id }}</b></v-col>
      <v-col cols="4" class="text-right">User: {{ username }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ProgressLinearTimeout v-if="isLoading" />
        <table class="table-picking">
          <thead>
            <tr>
              <th>
                Code
              </th>
              <th>
                Description
              </th>
              <th>
                Qty
              </th>
              <th>
                Unit
              </th>
              <th>
                Bin
              </th>
              <th>
                Printer
              </th>
              <th>
                Picked
              </th>
              <th v-if="canEdit" />
            </tr>
          </thead>
          <tbody v-if="lines.length > 0">
            <tr v-for="(line, index) in lines" :key="index">
              <td>{{ line.itemCode }}</td>
              <td>{{ line.itemDescription }}</td>
              <td>
                <input
                  v-if="canEdit"
                  v-model.number="line.originalQuantity"
                  type="number"
                  @focus="onFocus"
                />
                <span v-else>
                  {{ line.originalQuantity }}
                </span>
              </td>
              <td>{{ line.unitOfMeasure }}</td>
              <td>{{ line.bin }}</td>
              <td>{{ line.printer }}</td>
              <td>{{ line.quantity }}</td>
              <td v-if="canEdit">
                <v-btn color="error" @click="removeLine(line)">
                  Delete
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row v-if="canEdit">
      <v-col cols="4" v-if="salesOrder">
        <PrintPickingSlip
          :sales-order="salesOrder" :lines="lines" @updatePrintCount="updatePrintCount"
        />
      </v-col>
      <v-col cols="4" class="flex flex-column align-end">
        <v-btn block color="accent" class="mb-5" @click="save" :loading="isSaving">Save</v-btn>
      </v-col>
      <v-col cols="4" class="flex flex-column align-end">
        <v-btn block color="success" class="mb-5" :to="{
            name: 'shop-PickSalesOrder',
            query: {
              orderId: order.id,
              salesOrderId: salesOrder.id,
            },
          }">
            Pick
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import PrintPickingSlip from '../components/PrintPickingSlip.vue';
import DialogAddSalesOrderLine from '../components/DialogAddSalesOrderLine.vue';
import SalesOrderHeader from '../components/SalesOrderHeader.vue';

export default {
  name: 'ViewSalesOrder',
  components: {
    DialogAddSalesOrderLine,
    PrintPickingSlip,
    SalesOrderHeader,
    ProgressLinearTimeout,
  },
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      salesOrderId: this.$route.query.salesOrderId,
      orderId: this.$route.query.orderId,
      order: undefined,
      lines: [],
    };
  },
  computed: {
    ...mapState('auth', ['username']),
    ...mapState('salesOrders', ['salesOrder']),
    canEdit() {
      return this.salesOrder?.status === 'Validated';
    },
  },
  mounted() {
    this.SET_title(`Sales Order: ${this.$route.query.salesOrderId}`);
    this.refresh();
  },
  methods: {
    ...mapActions('salesOrders', ['LOAD_salesOrder']),
    ...mapActions('salesOrderLines', ['LOAD_salesOrderLinesBySalesOrder']),
    ...mapActions('orders', ['LOAD_order']),
    ...mapActions('customers', ['LOAD_customerById']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    onFocus(event) {
      event.target.select();
    },
    async addStockOrderLine(line) {
      try {
        await this.$http.post('/odata/salesOrderLines', {
          salesOrderId: parseInt(this.salesOrderId, 10),
          tax: 0,
          price: 0,
          taxPercentage: 0,
          total: 0,
          itemDescription: line.itemDescription,
          unitOfMeasure: line.unitOfMeasure,
          quantity: line.quantity,
          originalQuantity: line.quantity,
          itemCode: line.itemCode,
          bin: line.binLocation,
        });
        await this.refresh();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error adding line');
      }
    },
    async refresh() {
      try {
        this.isLoading = true;
        await Promise.all([
          (async () => {
            await this.LOAD_salesOrder(this.salesOrderId);
          })(),
          (async () => {
            this.order = await this.LOAD_order(this.orderId);
          })(),
          (async () => {
            this.lines = await this.LOAD_salesOrderLinesBySalesOrder(this.salesOrderId);
          })(),
        ]);
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error loading picking slip');
      } finally {
        this.isLoading = false;
      }
    },
    async removeLine(line) {
      const res = await this.$confirm('Are you sure you want to delete this line?');
      if (!res) return;
      try {
        await this.$http.delete(`/odata/salesOrderLines(${line.id})`);
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error deleting line');
      }
      await this.refresh();
    },
    async complete() {
      try {
        this.isSaving = true;
        await this.$http.post(`/odata/salesOrders(${this.salesOrderId})/pick`, {
          model: {
            salesOrderId: parseInt(this.salesOrderId, 10),
            lines: this.lines.map((l) => ({
              id: l.id,
              quantity: l.quantity,
            })),
          },
        });
        this.$root.$emit('toast:success', 'Picking slip completed');
        this.$router.push({ name: 'shop-AdminOrders' });
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error saving picking slip');
      } finally {
        this.isSaving = false;
      }
    },
    async updatePrintCount() {
      await this.$http.patch(`/odata/salesOrders(${this.salesOrder.id})`, {
        printCount: this.salesOrder.printCount + 1,
      });
      await this.refresh();
    },
    async save() {
      try {
        this.isSaving = true;
        this.lines.forEach(async (l) => {
          await this.$http.patch(`/odata/salesOrderLines(${l.id})`, l);
        });
        this.$root.$emit('toast:notify', 'Sales order saved');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error saving sales order');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
<style>
.table-picking {
  width: 100%;
}
.table-picking tbody td,
.table-picking thead th {
  text-align: left;
  padding: 5px;
}
.table-picking thead th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.table-picking input {
  width: 50px;
  text-align: right;
  padding: 5px;
  border: 1px solid #ccc;
}
</style>
