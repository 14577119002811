var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table-cart mb-4" }, [
    _vm._m(0),
    _c(
      "tbody",
      [
        _vm._l(_vm.cartItems, function (item, index) {
          return [
            _c(
              "tr",
              { key: index, attrs: { "data-cy": `table-cart-row-${index}` } },
              [
                _c("td", [
                  item.stockItem.photo
                    ? _c("img", {
                        attrs: {
                          width: "64",
                          height: "64",
                          contain: "",
                          src: _vm.resizeImage(item.stockItem),
                        },
                      })
                    : _c("img", {
                        attrs: {
                          width: "64",
                          height: "64",
                          contain: "",
                          src: require("../assets/default-product-image.png"),
                        },
                      }),
                ]),
                _c("td", [
                  _c("div", { staticClass: "text-normal" }, [
                    _vm._v(_vm._s(item.stockItem.description)),
                  ]),
                ]),
                _c("td", [_vm._v(" " + _vm._s(item.stockItem.quantity) + " ")]),
                _c(
                  "td",
                  [
                    _vm.disabled
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.formatNumber(item.amount))),
                        ])
                      : _c("QuantityButtonCart", {
                          attrs: {
                            "data-cy": "cart-quantity-button",
                            limit: item.detail.limit?.limit,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.CHANGE_AMOUNT({
                                itemCode: item.stockItem.itemCode,
                                amount: $event,
                              })
                            },
                            remove: function ($event) {
                              return _vm.REMOVE_CART(item.stockItem.itemCode)
                            },
                          },
                          model: {
                            value: item.amount,
                            callback: function ($$v) {
                              _vm.$set(item, "amount", $$v)
                            },
                            expression: "item.amount",
                          },
                        }),
                  ],
                  1
                ),
                _c("td", [
                  _c("div", { staticClass: "text-normal" }, [
                    _vm._v("R" + _vm._s(_vm.formatCurrency(item.price))),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "text-normal" }, [
                    _vm._v("R" + _vm._s(_vm.formatCurrency(item.tax))),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "text-normal" }, [
                    _vm._v("R" + _vm._s(_vm.formatCurrency(item.total))),
                  ]),
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          color: "error",
                          disabled: _vm.disabled,
                          "data-cy": "remove-cart-item",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.REMOVE_CART(item.stockItem.itemCode)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v(_vm._s(_vm.mdiTrashCan)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        }),
        _c("tr", [
          _c(
            "td",
            { attrs: { colspan: "3" } },
            [
              !_vm.disabled
                ? _c(
                    "v-btn",
                    { attrs: { color: "error" }, on: { click: _vm.clearCart } },
                    [_vm._v("Clear entire cart")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "td",
            { staticClass: "small-td text-right", attrs: { colspan: "5" } },
            [
              _c("h2", [
                _c("span", [_vm._v("Total:")]),
                _vm._v(
                  " R " + _vm._s(_vm.formatCurrency(_vm.total_amount)) + " "
                ),
              ]),
            ]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Product")]),
        _c("th", [_vm._v("Stock")]),
        _c("th", [_vm._v("Qty")]),
        _c("th", [_vm._v("Price")]),
        _c("th", [_vm._v("VAT")]),
        _c("th", [_vm._v("Total")]),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }