<template>
  <span>
    {{ customer }}
  </span>
</template>

<script>
export default {
  name: 'CustomerName',
  props: {
    customers: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    customer() {
      const { customers, id } = this;
      return customers
        .find((customer) => customer.id === id || customer.customerId === id)
        ?.customerDesc ?? this.id;
    },
  },
};
</script>
