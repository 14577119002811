var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Breadcrumbs", { attrs: { history: _vm.history } }),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.isLoading,
          "sort-by": "created",
          "sort-desc": "",
          "show-select": "",
          "footer-props": {
            "items-per-page-options": [5, 10, 25, 50, 100, 250, 500, 1000, -1],
            "items-per-page-text": _vm.$t("common.itemsPerPage"),
            "show-current-page": true,
            "show-first-last-page": true,
            "show-previous-next-page": true,
          },
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { "max-width": "600px" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { color: "primary" } },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Import ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialog,
                                    callback: function ($$v) {
                                      _vm.dialog = $$v
                                    },
                                    expression: "dialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _c(
                                          "span",
                                          { staticClass: "headline" },
                                          [_vm._v("Import Favourites")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-container",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          multiple: "",
                                                          items: _vm.customers,
                                                          "item-text":
                                                            "customerDesc",
                                                          "item-value":
                                                            "customerCode",
                                                          label: "Customer(s)",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.importCustomers,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.importCustomers =
                                                              $$v
                                                          },
                                                          expression:
                                                            "importCustomers",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items: _vm.stockItems,
                                                          "item-text":
                                                            "description",
                                                          "item-value":
                                                            "itemCode",
                                                          label: "Stock Item",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.importStockItem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.importStockItem =
                                                              $$v
                                                          },
                                                          expression:
                                                            "importStockItem",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialog = false
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: _vm.importFavourites,
                                              },
                                            },
                                            [_vm._v("Import")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-dialog",
                                {
                                  attrs: { "max-width": "600px" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    disabled:
                                                      _vm.selected.length === 0,
                                                    color: "primary",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(" Replace All "),
                                              _vm.itemsToReplace.length > 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      " (" +
                                                        _vm._s(
                                                          _vm.itemsToReplace
                                                            .length
                                                        ) +
                                                        ") "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialogReplace,
                                    callback: function ($$v) {
                                      _vm.dialogReplace = $$v
                                    },
                                    expression: "dialogReplace",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _c(
                                          "span",
                                          { staticClass: "headline" },
                                          [_vm._v("Replace Favourites")]
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-container",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items: _vm.stockItems,
                                                          "item-text":
                                                            "description",
                                                          "item-value":
                                                            "itemCode",
                                                          label: "Stock Item",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.replaceStockItem,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.replaceStockItem =
                                                              $$v
                                                          },
                                                          expression:
                                                            "replaceStockItem",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialogReplace = false
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: { click: _vm.replaceAll },
                                            },
                                            [_vm._v("Replace")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    color: "success",
                                    disabled: _vm.selected.length === 0,
                                    "data-cy":
                                      "StockItemAlternative-button-deleteAll",
                                  },
                                  on: { click: _vm.removeAll },
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" " + _vm._s(_vm.mdiTrashCan) + " "),
                                  ]),
                                  _vm._v(" Delete All "),
                                  _vm.itemsToRemove.length > 0
                                    ? _c("span", [
                                        _vm._v(
                                          " (" +
                                            _vm._s(_vm.itemsToRemove.length) +
                                            ") "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "primary" },
                                  on: { click: _vm.exportFavourites },
                                },
                                [_vm._v(" Export ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                disabled: _vm.selectedStockItem?.length > 0,
                                clearable: "",
                                items: _vm.customers,
                                "item-text": "customerDesc",
                                "item-value": "customerCode",
                                label: "Filter by Customer",
                              },
                              model: {
                                value: _vm.selectedCustomer,
                                callback: function ($$v) {
                                  _vm.selectedCustomer = $$v
                                },
                                expression: "selectedCustomer",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                disabled: _vm.selectedCustomer?.length > 0,
                                clearable: "",
                                items: _vm.stockItems,
                                "item-text": "description",
                                "item-value": "itemCode",
                                label: "Filter by Stock Item",
                              },
                              model: {
                                value: _vm.selectedStockItem,
                                callback: function ($$v) {
                                  _vm.selectedStockItem = $$v
                                },
                                expression: "selectedStockItem",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.name",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.getCustomerName(item.name)) + " "),
              ]
            },
          },
          {
            key: "item.identifier",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.getItemName(item.identifier)) + " "),
              ]
            },
          },
          {
            key: "item.created",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDateTime(item.created)) + " "),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { small: "", color: "error" },
                    on: {
                      click: function ($event) {
                        return _vm.remove(item)
                      },
                    },
                  },
                  [_vm._v("Remove")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }