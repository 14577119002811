<template>
  <div>
    <v-btn block color="info" @click="open">
      Print Picking Slip ({{ salesOrder.printCount }})
    </v-btn>
  </div>
</template>

<script>
import PickingSlipTemplate from './PickingSlipTemplate.txt';
import PickingSlipRowTemplate from './PickingSlipRowTemplate.txt';

export default {
  name: 'PrintText',
  props: {
    salesOrder: {
      type: Object,
      required: true,
    },
    lines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      PickingSlipTemplate,
      PickingSlipRowTemplate,
    };
  },
  computed: {
    deliveryInfo() {
      return this.order;
    },
    deliveryLines() {
      return this.lines.map((item) => ({
        code: item.code,
        description: item.description,
        qty: item.qty,
        unit: item.unit,
        bin: item.bin,
      }));
    },
    linesByPrinterArray() {
      const groupedLines = this.lines.reduce((acc, line) => {
        if (!acc[line.printer]) {
          acc[line.printer] = [];
        }
        acc[line.printer].push(line);
        return acc;
      }, {});

      return Object.keys(groupedLines).map((printer) => ({
        name: printer,
        lines: groupedLines[printer],
      }));
    },
  },
  methods: {
    open() {
      this.$emit('updatePrintCount');
      this.printText();
    },
    printText() {
      this.linesByPrinterArray.forEach((printer) => {
        this.printTextToPrinter(printer.name, printer.lines);
      });
    },
    printTextToPrinter(printer, lines) {
      const text = this.generateText(printer, lines);
      // Create a hidden iframe to load the text content for printing
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = '0';
      document.body.appendChild(iframe);

      // Write the text content into the iframe with Times New Roman font
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
      <style>
        pre {
        font-family: 'Times New Roman', Times, serif;
        font-size: 14px;
        }
      </style>
      <pre>${text}</pre>
      `);
      doc.close();

      // Print the content of the iframe
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    },
    generateText(printer, lines) {
      return this.PickingSlipTemplate
        .replace('[CompanyName]', this.salesOrder.customerName.padEnd(56, ' '))
        .replace('[DeliveryAddress1]', this.salesOrder.deliveryAddress1.padEnd(56, ' '))
        .replace('[DeliveryAddress2]', this.salesOrder.deliveryAddress2.padEnd(56, ' '))
        .replace('[DeliveryAddress3]', this.salesOrder.deliveryAddress3.padEnd(56, ' '))
        .replace('[DeliveryAddress4]', this.salesOrder.deliveryAddress4.padEnd(56, ' '))
        .replace('[DeliveryDate]', this.formatDate(this.salesOrder.deliveryDate))
        .replace('[CreatedBy]', this.salesOrder.createdBy.padEnd(50, ' '))
        .replace('[SalesOrderId]', this.salesOrder.id?.toString())
        .replace('[PrintedDate]', this.formatDateTime(new Date()))
        .replace('[Version]', this.salesOrder.printCount + 1)
        .replace('[Printer]', printer)
        .replace('[Rows]', lines.map((line) => this.PickingSlipRowTemplate
          .replace('[ItemCode]', line.itemCode.padEnd(14, ' '))
          .replace('[Description]', line.itemDescription.padEnd(36, ' '))
          .replace('[Quantity]', line.quantity.toString().padEnd(5, ' '))
          .replace('[UnitOfMeasure]', line.unitOfMeasure.padEnd(7, ' '))
          .replace('[Bin]', line.bin?.padEnd(10, ' '))).join('\n'));
    },
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap; /* Ensure the text is wrapped properly */
  word-wrap: break-word; /* Ensure long words are wrapped properly */
}
</style>
