var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "row-item",
      attrs: {
        "data-cy": `mobileStockItem-row-${_vm.index}`,
        "no-gutters": "",
      },
    },
    [
      _c(
        "v-col",
        {
          staticStyle: { "margin-top": "auto", "margin-bottom": "auto" },
          attrs: { cols: "2" },
        },
        [
          _c("v-img", {
            attrs: { contain: "", height: "70", src: _vm.stockItem.photo },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pr-1", attrs: { cols: "6", md: "8", sm: "8" } },
        [
          _c("div", [_vm._v(" " + _vm._s(_vm.stockItem.description) + " ")]),
          _c("div", { staticClass: "align-mobile-bottom" }, [
            _c("div", { staticClass: "font-smaller" }, [
              _vm._v(
                " R " +
                  _vm._s(_vm.formatCurrency(_vm.detail.price)) +
                  " / " +
                  _vm._s(_vm.stockItem.unitOfMeasure) +
                  " "
              ),
              _vm.detail.tax > 0
                ? _c("span", [
                    _vm._v(
                      "(R " +
                        _vm._s(_vm.formatCurrency(_vm.detail.tax)) +
                        " VAT)"
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c(
        "v-col",
        { attrs: { cols: "4", md: "2", sm: "2" } },
        [
          _vm.isCheckout
            ? _c("QuantityButtonCart", {
                attrs: {
                  clearOnFocus: !_vm.isCheckout,
                  value: _vm.localAmount,
                },
                on: {
                  input: _vm.update,
                  remove: function ($event) {
                    return _vm.$emit("remove", _vm.stockItem)
                  },
                },
              })
            : _c("QuantityButtonBasic", {
                attrs: { stockItem: _vm.stockItem },
                on: { add: _vm.add },
              }),
          _vm.detail.qty > 0
            ? _c("div", { staticClass: "font-smaller mt-2" }, [
                _vm._v(" " + _vm._s(_vm.detail.qty) + " items in stock "),
              ])
            : _c("div", { staticClass: "font-smaller mt-2" }, [
                _c("span", { staticClass: "red--text" }, [
                  _vm._v("Out of stock"),
                ]),
              ]),
          _vm.showAlternatives
            ? _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    block: "",
                    outlined: "",
                    color: "primary",
                    to: _vm.categoryLink,
                  },
                },
                [_vm._v(" Alternatives ")]
              )
            : _vm._e(),
          _vm.detail.qty === 0 && _vm.showAlternatives
            ? _c("div", { staticClass: "mt-2" })
            : _vm._e(),
          _vm.detail.qty === 0 && _vm.showAlternatives
            ? _c("BuyInStock", {
                staticClass: "mt-2",
                attrs: { stockItem: _vm.stockItem, small: true },
              })
            : _vm._e(),
          _vm.showTotal
            ? _c(
                "span",
                { staticClass: "display-block text-center font-bold mt-2" },
                [_vm._v(" R " + _vm._s(_vm.formatCurrency(_vm.total)) + " ")]
              )
            : _vm._e(),
          _c("div", { staticClass: "mb-2" }),
          _vm.showFavourites && _vm.activeCustomer
            ? _c("Favourite", {
                attrs: {
                  identifier: _vm.stockItem.itemCode.toString(),
                  name: `stockItem-${_vm.activeCustomer.customerCode}`,
                  small: true,
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("favouriteChange")
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "mb-2" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }