<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <ProgressLinearTimeout v-if="loadingHeader" />
    <div v-if="!loadingHeader" class="text-h5">Deliveries:</div>
    <AdminOrderSummary v-if="!loadingHeader" :orders="orderHeaders" @click="onDateFilter" />
    <v-card>
      <v-card-text>
        <v-data-table
          elevation-1
          :headers="headers"
          :items="visibleOrders"
          @refresh="refresh"
          :expanded.sync="expanded"
          :loading="isLoading"
          show-expand
          :show-select="selectedFilter === 'Ready'"
          v-model="selectedOrders"
          item-key="id"
          :search="search"
          sort-by="created"
          :sort-desc="true"
          no-data-text="All orders have been processed"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12" lg="2">
                <DatePicker
                  v-model="deliveryDate"
                  label="Delivery Date"
                  :filled="false"
                  clearable
                  @input="refresh"
                />
              </v-col>
              <v-col cols="12" lg="2">
                <DatePicker
                  v-model="start"
                  label="From"
                  :filled="false"
                  :disabled="deliveryDate !== undefined"
                  @input="refresh"
                />
              </v-col>
              <v-col cols="12" lg="2">
                <DatePicker
                v-model="end"
                  label="To"
                  :filled="false"
                  :disabled="deliveryDate !== undefined"
                  @input="refresh"
                />
              </v-col>
              <v-col cols="11" lg="2">
                <v-select
                  clearable
                  data-cy="TableBasic-filter"
                  class="mr-2"
                  v-model="selectedFilter"
                  :items="['Picked', 'Ready']"
                  label="Filter"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col cols="11" lg="2">
                <v-text-field
                  clearable
                  data-cy="TableBasic-search"
                  class="mr-2"
                  v-model="search"
                  :append-icon="mdiMagnify"
                  :label="$t('common.search')"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col cols="2" class="d-flex justify-start align-center">
                <v-checkbox id="input-show-completed" v-model="showCompleted">
                </v-checkbox>
                <label for="input-show-completed">Show Completed</label>
                <v-btn
                  icon color="primary" @click="refresh">
                  <v-icon>{{ mdiRefresh }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="selectedOrders.length > 0" class="mt-0">
              <v-col cols="2">
                <PrintPickingSlips :orders="selectedOrders" />
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.created="{ item }">
            {{ formatDateTime(item.created) }}
          </template>
          <template v-slot:item.customerId="{ item }">
            <CustomerName :customers="customers" :id="item.customerId" />
          </template>
          <template v-slot:item.area="{ item }">
            <CustomerArea :customers="customers" :arCustomers="arCustomers" :id="item.customerId" />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 10px">
              <v-data-table
                :headers="lineHeaders"
                :items="item.cart"
                :loading="item.isLoading"
                elevation-0
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:item.price="{ item }">
                  {{ formatNumber(item.price) }}
                </template>
                <template v-slot:item.vat="{ item }">
                  {{ formatNumber(item.vat) }}
                </template>
                <template v-slot:item.total="{ item }">
                  {{ formatNumber(item.total) }}
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:item.externalOrderNo="{ item }">
            <router-link
                :to="{
                  name: 'shop-ViewSalesOrder',
                  query: {
                    orderId: item.id,
                    salesOrderId: item.externalOrderNo,
                  },
                }">
                {{ item.externalOrderNo }}
            </router-link>
          </template>
          <template v-slot:item.updated="{ item }">
            {{ formatDateTime(item.updated) }}
          </template>
          <template v-slot:item.deliveryDate="{ item }">
            <v-btn
              class="ml-2"
              color="primary"
              small
              icon
              :disabled="item.status === 'Integrated'"
              @click="editDeliveryDate(item)"
            >
              <v-icon small>{{ mdiPencil }}</v-icon>
            </v-btn>
            {{ item.deliveryDate }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex flex-column pa-2">
              <v-btn
                small
                color="success"
                class="mb-2"
                @click="requeue(item)"
                :disabled="item.status !== 'Failed'"
                :loading="item.isSaving"
                block
              >
                Requeue
              </v-btn>
              <XmlAuditDialog :orderId="item.id" />
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogDeliveryDate" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 d-flex flex-column align-start">
          <span>
            Editing Order {{ editItem.orderNo }}
          </span>
          <span class="text-caption">
            Created by {{ editItem.createdBy }}
          </span>
        </v-card-title>
        <v-card-text>
          <DatePicker
            v-model="editItem.deliveryDate"
            label="Delivery Date"
            :filled="false"
            clearable
          />
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogDeliveryDate = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="success" :loading="isSavingItem" @click="updateDeliveryDate(editItem)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mdiMagnify, mdiRefresh, mdiPencil } from '@mdi/js';
import dayjs from 'dayjs';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import DatePicker from '~src/components/DatePicker.vue';
import AdminOrderSummary from '../components/AdminOrderSummary.vue';
import XmlAuditDialog from '../components/XmlAuditDialog.vue';
import CustomerName from '../components/CustomerName.vue';
import CustomerArea from '../components/CustomerArea.vue';
import PrintPickingSlips from '../components/PrintPickingSlips.vue';

export default {
  name: 'AdminOrders',
  components: {
    CustomerArea,
    CustomerName,
    ProgressLinearTimeout,
    DatePicker,
    PrintPickingSlips,
    AdminOrderSummary,
    XmlAuditDialog,
  },
  mixins: [loading],
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      mdiPencil,
      customers: [],
      arCustomers: [],
      dialogDeliveryDate: false,
      editItem: {},
      isSavingItem: false,
      expanded: [],
      loadingHeader: false,
      deliveryDate: undefined,
      showCompleted: false,
      search: '',
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Orders',
        disabled: true,
        depth: 0,
      }],
      orders: [],
      orderHeaders: [],
      selectedOrders: [],
      selectedFilter: '',
      start: dayjs().add(-2, 'day').format('YYYY-MM-DD'),
      end: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      lineHeaders: [
        {
          text: 'Quantity',
          value: 'amount',
        },
        {
          text: 'Item Code',
          value: 'itemCode',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Tax',
          value: 'tax',
        },
        {
          text: 'Total',
          value: 'total',
        },
      ],
      headers: [
        {
          text: 'Created',
          value: 'created',
        },
        {
          text: 'Customer',
          value: 'customerId',
        },
        {
          text: 'Area',
          value: 'area',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Delivery Date',
          value: 'deliveryDate',
        },
        {
          text: 'Sales Order',
          value: 'externalOrderNo',
        },
        // {
        //   text: 'Old Order ID',
        //   value: 'oldOrderId',
        // },
        // {
        //   text: 'Customer',
        //   value: 'customerCode',
        // },
        {
          text: 'Customer Order No',
          value: 'orderNo',
        },
        {
          text: 'Created By',
          value: 'createdBy',
        },
        {
          text: 'Last Updated',
          value: 'updated',
        },
        {
          text: 'Errors',
          value: 'errors',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    visibleOrders() {
      if (this.showCompleted) {
        return this.orders;
      }
      if (this.deliveryDate) {
        return this.orders;
      }
      return this.orders.filter((o) => o.status !== 'Integrated');
    },
  },
  watch: {
    expanded() {
      this.loadOrderLines();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async loadCustomers() {
      const oDatUrl = '/odata/customers?$select=id,customerCode,customerDesc,customerId';
      const { data } = await this.$http.get(oDatUrl);
      this.customers = data.value;
    },
    editDeliveryDate(item) {
      this.editItem = item;
      this.dialogDeliveryDate = true;
    },
    onDateFilter(date) {
      this.deliveryDate = dayjs(date).format('YYYY-MM-DD');
      this.refresh();
    },
    async loadOrderLines() {
      this.expanded.forEach(async (item) => {
        if (item.cart) return;
        this.orders[this.orders.indexOf(item)].isLoading = true;
        const { data } = await this.$http.get(`/odata/orders?$filter=Id eq ${item.id}&$select=Id,Cart&$expand=Cart($select=amount,itemCode,price,tax,total)`);
        // eslint-disable-next-line prefer-destructuring
        this.orders[this.orders.indexOf(item)].cart = data.value[0].cart;
        this.orders[this.orders.indexOf(item)].isLoading = false;
      });
    },
    /**
     * Executes refresh
     */
    async refresh() {
      await Promise.all([
        (async () => {
          await this.refreshOrders();
        })(),
        (async () => {
          await this.refreshHeader();
        })(),
        (async () => {
          await this.loadCustomers();
        })(),
      ]);
    },
    async refreshOrders() {
      this.isLoading = true;
      const { start, end } = this;
      let oDataUrl = `/odata/orders?$filter=IsDeleted eq false AND created ge ${start} and created le ${end}&$select=id,deliveryDate,created,customerId,status,externalOrderNo,orderNo,createdBy&$orderby=deliveryDate desc`;
      if (this.deliveryDate) {
        const deliveryDate = dayjs(this.deliveryDate).format('YYYY/MM/DD');
        oDataUrl = `/odata/orders?$filter=IsDeleted eq false AND (startsWith(deliveryDate, '${this.deliveryDate}') OR startsWith(deliveryDate, '${deliveryDate}'))&$expand=orderDetail,cart&$select=id,deliveryDate,created,customerId,status,externalOrderNo,orderNo,createdBy&$orderby=deliveryDate desc`;
      }
      const { data } = await this.$http.get(oDataUrl);
      this.orders = data.value.map((order) => ({
        ...order,
        isSaving: false,
        isLoading: false,
      }));
      this.isLoading = false;
    },
    async refreshHeader() {
      this.loadingHeader = true;
      const start = dayjs().add(-10, 'day').format('YYYY-MM-DD');
      const end = dayjs().add(1, 'day').format('YYYY-MM-DD');
      const filter = `IsDeleted eq false AND created ge ${start} and created le ${end}`;
      const expand = 'cart($select=total)';
      const select = 'id,deliveryDate,created&$orderby=deliveryDate asc';
      const oDataUrl = `/odata/orders?$filter=${filter}&$expand=${expand}&$select=${select}`;
      const { data } = await this.$http.get(oDataUrl);
      this.orderHeaders = data.value;
      this.loadingHeader = false;
    },
    async updateDeliveryDate(item) {
      try {
        this.isSavingItem = true;
        await this.$http.patch(`/odata/orders/${item.id}`, { deliveryDate: item.deliveryDate });
        this.dialogDeliveryDate = false;
        await this.refresh();
        this.$root.$emit('toast:notify', 'Delivery Date Updated');
        this.editItem = {};
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error updating delivery date');
      } finally {
        this.isSavingItem = false;
      }
    },
    /**
     * Send post to orders api using the order id
     */
    async requeue(item) {
      this.orders[this.orders.indexOf(item)].isSaving = true;
      const result = await this.$confirm('Are you sure you want to validate this order again?');
      if (result) {
        try {
          this.isLoading = true;
          await this.$http.post(`/api/orders/${item.id}/requeue?sendConfirmation=false`);
          this.$root.$emit('toast:notify', 'Queued order');
        } catch (error) {
          this.$log.error(error);
          this.$root.$emit('toast:error', 'Error validating order');
        } finally {
          this.isLoading = false;
          this.orders[this.orders.indexOf(item)].isSaving = false;
          await this.refresh();
        }
      } else {
        this.orders[this.orders.indexOf(item)].isSaving = false;
      }
    },
  },
};
</script>
<style scoped>
.table-line {
  width: 100%;
  border-collapse: collapse;
}
</style>
