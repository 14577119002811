<template>
  <span>
    {{ arCustomer.area }}
  </span>
</template>

<script>
export default {
  name: 'CustomerName',
  props: {
    customers: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      arCustomer: {
        area: '',
      },
    };
  },
  computed: {
    customer() {
      const { customers, id } = this;
      return customers.find((c) => c.id === id || c.customerId === id);
    },
  },
  watch: {
    customer() {
      console.log(this.customer);
      this.getCustomer();
    },
  },
  mounted() {
    this.getCustomer();
  },
  methods: {
    async getCustomer() {
      if (!this.customer?.customerCode) return;
      const oDataUrl = `/odata/arCustomers?$filter=shortName eq '${this.customer.customerCode}'`;
      const { data } = await this.$http.get(oDataUrl);
      if (data?.value?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.arCustomer = data.value[0];
      }
      console.log(data);
    },
  },
};
</script>
